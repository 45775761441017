import { render } from "./CountersTable.vue?vue&type=template&id=315fbf8e"
import script from "./CountersTable.vue?vue&type=script&lang=ts"
export * from "./CountersTable.vue?vue&type=script&lang=ts"
script.render = render
/* hot reload */
if (module.hot) {
  script.__hmrId = "315fbf8e"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('315fbf8e', script)) {
    api.reload('315fbf8e', script)
  }
  
  module.hot.accept("./CountersTable.vue?vue&type=template&id=315fbf8e", () => {
    api.rerender('315fbf8e', render)
  })

}

script.__file = "desktop/core/src/desktop/js/apps/jobBrowser/components/impalaQueries/query-details/counters-table/CountersTable.vue"

export default script