import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, toDisplayString as _toDisplayString, createElementBlock as _createElementBlock } from "vue";
var _hoisted_1 = {
  key: 1
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_VarianceCell = _resolveComponent("VarianceCell");
  var _component_HueTable = _resolveComponent("HueTable");
  return _openBlock(), _createElementBlock("div", null, [_ctx.rows.length ? (_openBlock(), _createBlock(_component_HueTable, {
    key: 0,
    columns: _ctx.columns,
    rows: _ctx.rows
  }, {
    "cell-variance": _withCtx(function (row) {
      return [_createVNode(_component_VarianceCell, {
        data: row
      }, null, 8 /* PROPS */, ["data"])];
    }),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["columns", "rows"])) : (_openBlock(), _createElementBlock("h2", _hoisted_1, _toDisplayString(_ctx.I18n('No counters available!')), 1 /* TEXT */))]);
}