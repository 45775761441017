import { defineComponent } from 'vue';
import Duration from "../../../../../components/Duration.vue";
import SqlText from "../../../../../components/SqlText.vue";
import TimeAgo from "../../../../../components/TimeAgo.vue";
import HumanByteSize from "../../../../../components/HumanByteSize.vue";
import LabeledInfo from "./LabeledInfo.vue";
export default defineComponent({
  components: {
    Duration: Duration,
    TimeAgo: TimeAgo,
    SqlText: SqlText,
    HumanByteSize: HumanByteSize,
    LabeledInfo: LabeledInfo
  },
  props: {
    query: {
      type: Object,
      required: true
    },
    layout: {
      type: String,
      "default": 'row'
    }
  }
});