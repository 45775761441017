import { defineComponent } from 'vue';
import { generateValueColumnKey } from "./CounterSet";
export default defineComponent({
  props: {
    data: {
      type: Object,
      required: true
    }
  },
  data: function data() {
    return {
      varianceTxt: '',
      variancePercent: 0
    };
  },
  watch: {
    data: function data() {
      var value1 = Number(this.data[generateValueColumnKey(0)]);
      var value2 = Number(this.data[generateValueColumnKey(1)]);
      if (isNaN(value1) || isNaN(value2) || value1 <= 0 || value2 <= 0) {
        this.varianceTxt = '';
      } else {
        this.variancePercent = value2 / (value1 + value2) * 100;
        var variance = value1 >= value2 ? value1 / value2 : value2 / value1;
        this.varianceTxt = "".concat(Number(variance.toFixed(2)), "x");
      }
    }
  }
});