import { render } from "./QueriesList.vue?vue&type=template&id=3840cf00"
import script from "./QueriesList.vue?vue&type=script&lang=ts"
export * from "./QueriesList.vue?vue&type=script&lang=ts"
script.render = render
/* hot reload */
if (module.hot) {
  script.__hmrId = "3840cf00"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('3840cf00', script)) {
    api.reload('3840cf00', script)
  }
  
  module.hot.accept("./QueriesList.vue?vue&type=template&id=3840cf00", () => {
    api.rerender('3840cf00', render)
  })

}

script.__file = "desktop/core/src/desktop/js/apps/jobBrowser/components/queriesList/QueriesList.vue"

export default script