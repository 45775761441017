import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
_pushScopeId("data-v-1ebc196d");
var _hoisted_1 = {
  "class": "hue-info-box"
};
_popScopeId();
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_LabeledInfo = _resolveComponent("LabeledInfo");
  var _component_StatusIndicator = _resolveComponent("StatusIndicator");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_LabeledInfo, {
    label: "Query ID",
    "class": "inline-info"
  }, {
    "default": _withCtx(function () {
      return [_createTextVNode(_toDisplayString(_ctx.query.queryId) + " ", 1 /* TEXT */), _createElementVNode("a", {
        href: "javascript:void(0);",
        "class": "fa fa-copy",
        onClick: _cache[0] || (_cache[0] = function ($event) {
          return _ctx.copyQueryId(_ctx.query.queryId);
        })
      })];
    }),
    _: 1 /* STABLE */
  }), _createVNode(_component_LabeledInfo, {
    label: "User",
    "class": "inline-info"
  }, {
    "default": _withCtx(function () {
      return [_createTextVNode(_toDisplayString(_ctx.query.userName), 1 /* TEXT */)];
    }),
    _: 1 /* STABLE */
  }), _createVNode(_component_LabeledInfo, {
    label: "Status",
    "class": "inline-info"
  }, {
    "default": _withCtx(function () {
      return [_createVNode(_component_StatusIndicator, {
        value: _ctx.query.status
      }, null, 8 /* PROPS */, ["value"]), _createTextVNode(" " + _toDisplayString(_ctx.query.status), 1 /* TEXT */)];
    }),
    _: 1 /* STABLE */
  })]);
}