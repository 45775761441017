import { defineComponent, inject } from 'vue';
import HueButton from "../../../../../components/HueButton.vue";
import Tab from "../../../../../components/Tab.vue";
import Tabs from "../../../../../components/Tabs.vue";
import QueryInfoTop from "./QueryInfoTop.vue";
import QueryInfo from "./QueryInfo.vue";
import CountersTable from "./counters-table/CountersTable.vue";
import I18n from "../../../../../utils/i18n";
import { get } from 'lodash';
export default defineComponent({
  components: {
    HueButton: HueButton,
    Tab: Tab,
    Tabs: Tabs,
    QueryInfoTop: QueryInfoTop,
    QueryInfo: QueryInfo,
    CountersTable: CountersTable
  },
  props: {
    queries: {
      type: Array,
      required: true
    }
  },
  setup: function setup() {
    var showQueries = inject('showQueries');
    return {
      showQueries: showQueries
    };
  },
  data: function data() {
    return {
      hideSimilarValues: false,
      useSameTimeScale: true
    };
  },
  methods: {
    get: get,
    I18n: I18n,
    getCounters: function getCounters(profile) {
      var kvToCounters = function kvToCounters(kv) {
        return Object.keys(kv).map(function (key) {
          return {
            counterName: key,
            counterValue: kv[key]
          };
        });
      };
      return [{
        counterGroupName: I18n('CPU Metrics'),
        counters: kvToCounters(profile.cpuMetrics)
      }, {
        counterGroupName: I18n('HDFS Metrics'),
        counters: kvToCounters(profile.hdfsMetrics)
      }, {
        counterGroupName: I18n('Memory Metrics'),
        counters: kvToCounters(profile.memoryMetrics)
      }, {
        counterGroupName: I18n('Thread Time Metrics'),
        counters: kvToCounters(profile.threadTimeMetrics)
      }];
    }
  }
});