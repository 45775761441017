import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
_pushScopeId("data-v-3ae86220");
var _hoisted_1 = {
  "class": "impala-queries"
};
_popScopeId();
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_Spinner = _resolveComponent("Spinner");
  var _component_InlineAlert = _resolveComponent("InlineAlert");
  var _component_query_details = _resolveComponent("query-details");
  var _component_query_details_diff = _resolveComponent("query-details-diff");
  var _component_query_table = _resolveComponent("query-table");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_ctx.loading ? (_openBlock(), _createBlock(_component_Spinner, {
    key: 0,
    size: "xlarge",
    center: true,
    overlay: true
  })) : _createCommentVNode("v-if", true), _ctx.error ? (_openBlock(), _createBlock(_component_InlineAlert, {
    key: 1,
    type: _ctx.AlertType.Error,
    message: _ctx.error.message,
    details: _ctx.error.details,
    "show-close": true,
    onClose: _ctx.showQueries
  }, null, 8 /* PROPS */, ["type", "message", "details", "onClose"])) : _ctx.selectedQuery ? (_openBlock(), _createBlock(_component_query_details, {
    key: 2,
    query: _ctx.selectedQuery,
    onReload: _cache[0] || (_cache[0] = function ($event) {
      return _ctx.selectedQuery && _ctx.querySelected(_ctx.selectedQuery);
    })
  }, null, 8 /* PROPS */, ["query"])) : _ctx.queriesToDiff ? (_openBlock(), _createBlock(_component_query_details_diff, {
    key: 3,
    queries: _ctx.queriesToDiff
  }, null, 8 /* PROPS */, ["queries"])) : (_openBlock(), _createBlock(_component_query_table, {
    key: 4,
    queries: _ctx.queries,
    "total-queries": _ctx.searchMeta && _ctx.searchMeta.size || 0,
    "update-time": _ctx.searchMeta && _ctx.searchMeta.updateTime || 0,
    onDiffQueries: _ctx.diffQueries,
    onQuerySelected: _ctx.querySelected,
    onReload: _cache[1] || (_cache[1] = function ($event) {
      return _ctx.lastFetchOptions && _ctx.fetch(_ctx.lastFetchOptions);
    }),
    onSearch: _ctx.fetch
  }, null, 8 /* PROPS */, ["queries", "total-queries", "update-time", "onDiffQueries", "onQuerySelected", "onSearch"]))]);
}