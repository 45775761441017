import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
_pushScopeId("data-v-f4743310");
var _hoisted_1 = {
  "class": "hue-info-box"
};
var _hoisted_2 = {
  "class": "hue-info-box"
};
var _hoisted_3 = {
  key: 0
};
var _hoisted_4 = {
  key: 1
};
_popScopeId();
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_sql_text = _resolveComponent("sql-text");
  var _component_LabeledInfo = _resolveComponent("LabeledInfo");
  var _component_time_ago = _resolveComponent("time-ago");
  var _component_duration = _resolveComponent("duration");
  var _component_HumanByteSize = _resolveComponent("HumanByteSize");
  return _openBlock(), _createElementBlock("div", {
    "class": _normalizeClass("query-info layout-".concat(_ctx.layout))
  }, [_createElementVNode("div", null, [_createElementVNode("div", _hoisted_1, [_createVNode(_component_LabeledInfo, {
    label: "Query"
  }, {
    "default": _withCtx(function () {
      return [_createVNode(_component_sql_text, {
        "enable-overflow": true,
        format: true,
        value: _ctx.query.queryText
      }, null, 8 /* PROPS */, ["value"])];
    }),
    _: 1 /* STABLE */
  })])]), _createElementVNode("div", null, [_createElementVNode("div", _hoisted_2, [_createVNode(_component_LabeledInfo, {
    label: "Start Time"
  }, {
    "default": _withCtx(function () {
      return [_ctx.query.startTime ? (_openBlock(), _createBlock(_component_time_ago, {
        key: 0,
        value: _ctx.query.startTime
      }, null, 8 /* PROPS */, ["value"])) : _createCommentVNode("v-if", true)];
    }),
    _: 1 /* STABLE */
  }), _createVNode(_component_LabeledInfo, {
    label: "End Time"
  }, {
    "default": _withCtx(function () {
      return [_ctx.query.endTime ? (_openBlock(), _createBlock(_component_time_ago, {
        key: 0,
        value: _ctx.query.endTime
      }, null, 8 /* PROPS */, ["value"])) : _createCommentVNode("v-if", true)];
    }),
    _: 1 /* STABLE */
  }), _createVNode(_component_LabeledInfo, {
    label: "Duration"
  }, {
    "default": _withCtx(function () {
      return [_ctx.query.duration ? (_openBlock(), _createBlock(_component_duration, {
        key: 0,
        value: _ctx.query.duration,
        "short": true
      }, null, 8 /* PROPS */, ["value"])) : _createCommentVNode("v-if", true)];
    }),
    _: 1 /* STABLE */
  }), _createVNode(_component_LabeledInfo, {
    label: "Query Type"
  }, {
    "default": _withCtx(function () {
      return [_createTextVNode(_toDisplayString(_ctx.query.queryType), 1 /* TEXT */)];
    }),
    _: 1 /* STABLE */
  }), _createVNode(_component_LabeledInfo, {
    label: "User Name"
  }, {
    "default": _withCtx(function () {
      return [_createTextVNode(_toDisplayString(_ctx.query.userName), 1 /* TEXT */)];
    }),
    _: 1 /* STABLE */
  }), _createVNode(_component_LabeledInfo, {
    label: "Coordinator"
  }, {
    "default": _withCtx(function () {
      return [_createTextVNode(_toDisplayString(_ctx.query.coordinator), 1 /* TEXT */)];
    }),
    _: 1 /* STABLE */
  }), _createVNode(_component_LabeledInfo, {
    label: "CPU Time"
  }, {
    "default": _withCtx(function () {
      return [_ctx.query.cpuTime ? (_openBlock(), _createBlock(_component_duration, {
        key: 0,
        value: _ctx.query.cpuTime,
        "short": true
      }, null, 8 /* PROPS */, ["value"])) : _createCommentVNode("v-if", true)];
    }),
    _: 1 /* STABLE */
  }), _createVNode(_component_LabeledInfo, {
    label: "Rows Produced"
  }, {
    "default": _withCtx(function () {
      return [_ctx.query.rowsProduced ? (_openBlock(), _createElementBlock("span", _hoisted_3, _toDisplayString(_ctx.query.rowsProduced), 1 /* TEXT */)) : (_openBlock(), _createElementBlock("span", _hoisted_4, "None"))];
    }),
    _: 1 /* STABLE */
  }), _createVNode(_component_LabeledInfo, {
    label: "Peak Memory"
  }, {
    "default": _withCtx(function () {
      return [_ctx.query.peakMemory ? (_openBlock(), _createBlock(_component_HumanByteSize, {
        key: 0,
        value: _ctx.query.peakMemory
      }, null, 8 /* PROPS */, ["value"])) : _createCommentVNode("v-if", true)];
    }),
    _: 1 /* STABLE */
  }), _createVNode(_component_LabeledInfo, {
    label: "HDFS Bytes Read"
  }, {
    "default": _withCtx(function () {
      return [_ctx.query.hdfsBytesRead ? (_openBlock(), _createBlock(_component_HumanByteSize, {
        key: 0,
        value: _ctx.query.hdfsBytesRead
      }, null, 8 /* PROPS */, ["value"])) : _createCommentVNode("v-if", true)];
    }),
    _: 1 /* STABLE */
  })])])], 2 /* CLASS */);
}