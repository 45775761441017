// Licensed to Cloudera, Inc. under one
// or more contributor license agreements.  See the NOTICE file
// distributed with this work for additional information
// regarding copyright ownership.  Cloudera, Inc. licenses this file
// to you under the Apache License, Version 2.0 (the
// "License"); you may not use this file except in compliance
// with the License.  You may obtain a copy of the License at
//
//     http://www.apache.org/licenses/LICENSE-2.0
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.

import $ from 'jquery';
import HueColors from "../../utils/hueColors";
import html2text from 'utils/html/html2text';
var isNotNullForCharts = function isNotNullForCharts(val) {
  return val !== 'NULL' && val !== null;
};
var jQuery = $;
var pieChartTransformer = function pieChartTransformer(rawDatum) {
  var _data = [];
  if (rawDatum.snippet.chartX() != null && rawDatum.snippet.chartYSingle() != null) {
    var _idxValue = -1;
    var _idxLabel = -1;
    rawDatum.snippet.result.meta().forEach(function (col, idx) {
      if (col.name === rawDatum.snippet.chartX()) {
        _idxLabel = idx;
      }
      if (col.name === rawDatum.snippet.chartYSingle()) {
        _idxValue = idx;
      }
    });
    var colors = HueColors.cuiD3Scale();
    $(rawDatum.counts()).each(function (cnt, item) {
      if (isNotNullForCharts(item[_idxValue])) {
        var val = item[_idxValue] * 1;
        if (isNaN(val)) {
          val = 0;
        }
        _data.push({
          label: html2text(item[_idxLabel]),
          value: val,
          color: colors[cnt % colors.length],
          obj: item
        });
      }
    });
  }
  if (rawDatum.sorting === 'asc') {
    _data.sort(function (a, b) {
      return a.value - b.value;
    });
  } else if (rawDatum.sorting === 'desc') {
    _data.sort(function (a, b) {
      return b.value - a.value;
    });
  }
  if (rawDatum.snippet.chartLimit()) {
    _data = _data.slice(0, rawDatum.snippet.chartLimit());
  }
  return _data;
};
var mapChartTransformer = function mapChartTransformer(rawDatum) {
  var _data = [];
  if (rawDatum.snippet.chartX() != null && rawDatum.snippet.chartYSingle() != null) {
    var _idxRegion = -1;
    var _idxValue = -1;
    rawDatum.snippet.result.meta().forEach(function (col, idx) {
      if (col.name === rawDatum.snippet.chartX()) {
        _idxRegion = idx;
      }
      if (col.name === rawDatum.snippet.chartYSingle()) {
        _idxValue = idx;
      }
    });
    $(rawDatum.counts()).each(function (cnt, item) {
      if (isNotNullForCharts(item[_idxValue]) && isNotNullForCharts(item[_idxRegion])) {
        _data.push({
          label: item[_idxRegion],
          value: item[_idxValue],
          obj: item
        });
      }
    });
  }
  if (rawDatum.snippet.chartLimit()) {
    _data = _data.slice(0, rawDatum.snippet.chartLimit());
  }
  return _data;
};

// The leaflet map can freeze the browser with numbers outside the map
var MIN_LAT = -90;
var MAX_LAT = 90;
var MIN_LNG = -180;
var MAX_LNG = 180;
var leafletMapChartTransformer = function leafletMapChartTransformer(rawDatum) {
  var _data = [];
  if (rawDatum.snippet.chartX() != null && rawDatum.snippet.chartYSingle() != null) {
    var _idxLat = -1;
    var _idxLng = -1;
    var _idxLabel = -1;
    var _idxHeat = -1;
    rawDatum.snippet.result.meta().forEach(function (col, idx) {
      if (col.name === rawDatum.snippet.chartX()) {
        _idxLat = idx;
      }
      if (col.name === rawDatum.snippet.chartYSingle()) {
        _idxLng = idx;
      }
      if (col.name === rawDatum.snippet.chartMapLabel()) {
        _idxLabel = idx;
      }
      if (col.name === rawDatum.snippet.chartMapHeat()) {
        _idxHeat = idx;
      }
    });
    if (rawDatum.snippet.chartMapLabel() != null) {
      $(rawDatum.counts()).each(function (cnt, item) {
        if (isNotNullForCharts(item[_idxLat]) && isNotNullForCharts(item[_idxLng])) {
          _data.push({
            lat: Math.min(Math.max(MIN_LAT, item[_idxLat]), MAX_LAT),
            lng: Math.min(Math.max(MIN_LNG, item[_idxLng]), MAX_LNG),
            label: html2text(item[_idxLabel]),
            isHeat: rawDatum.snippet.chartMapType() === 'heat',
            intensity: _idxHeat > -1 ? item[_idxHeat] * 1 != NaN ? item[_idxHeat] * 1 : null : null,
            obj: item
          });
        }
      });
    } else {
      $(rawDatum.counts()).each(function (cnt, item) {
        if (isNotNullForCharts(item[_idxLat]) && isNotNullForCharts(item[_idxLng])) {
          _data.push({
            lat: Math.min(Math.max(MIN_LAT, item[_idxLat]), MAX_LAT),
            lng: Math.min(Math.max(MIN_LNG, item[_idxLng]), MAX_LNG),
            isHeat: rawDatum.snippet.chartMapType() === 'heat',
            intensity: _idxHeat > -1 ? item[_idxHeat] * 1 != NaN ? item[_idxHeat] * 1 : null : null,
            obj: item
          });
        }
      });
    }
  }
  if (rawDatum.snippet.chartLimit()) {
    _data = _data.slice(0, rawDatum.snippet.chartLimit());
  }
  return _data;
};
var timelineChartTransformer = function timelineChartTransformer(rawDatum) {
  var _datum = [];
  var _plottedSerie = 0;
  rawDatum.snippet.result.meta().forEach(function (meta) {
    if (rawDatum.snippet.chartYMulti().indexOf(meta.name) > -1) {
      var col = meta.name;
      var _idxValue = -1;
      var _idxLabel = -1;
      rawDatum.snippet.result.meta().forEach(function (icol, idx) {
        if (icol.name === rawDatum.snippet.chartX()) {
          _idxLabel = idx;
        }
        if (icol.name === col) {
          _idxValue = idx;
        }
      });
      if (_idxValue > -1) {
        var _data = [];
        var colors = HueColors.cuiD3Scale();
        $(rawDatum.counts()).each(function (cnt, item) {
          if (isNotNullForCharts(item[_idxLabel]) && isNotNullForCharts(item[_idxValue])) {
            _data.push({
              series: _plottedSerie,
              x: new Date(moment(html2text(item[_idxLabel])).valueOf()),
              y: item[_idxValue] * 1,
              color: colors[_plottedSerie % colors.length],
              obj: item
            });
          }
        });
        if (rawDatum.sorting === 'asc') {
          _data.sort(function (a, b) {
            return a.y - b.y;
          });
        }
        if (rawDatum.sorting === 'desc') {
          _data.sort(function (a, b) {
            return b.y - a.y;
          });
        }
        if (rawDatum.snippet.chartLimit()) {
          _data = _data.slice(0, rawDatum.snippet.chartLimit());
        }
        _datum.push({
          key: col,
          values: _data
        });
        _plottedSerie++;
      }
    }
  });
  return _datum;
};
var multiSerieChartTransformer = function multiSerieChartTransformer(rawDatum) {
  var _datum = [];
  if (rawDatum.snippet.chartX() != null && rawDatum.snippet.chartYMulti().length > 0) {
    var _plottedSerie = 0;
    if (typeof rawDatum.snippet.chartXPivot() !== 'undefined') {
      var _idxValue = -1;
      var _idxLabel = -1;
      var _isXDate = false;
      rawDatum.snippet.result.meta().forEach(function (icol, idx) {
        if (icol.name === rawDatum.snippet.chartX()) {
          _isXDate = icol.type.toUpperCase().indexOf('DATE') > -1;
          _idxLabel = idx;
        }
        if (icol.name === rawDatum.snippet.chartYSingle()) {
          _idxValue = idx;
        }
      });
      rawDatum.snippet.result.meta().forEach(function (meta, cnt) {
        if (rawDatum.snippet.chartXPivot() === meta.name) {
          var _idxPivot = cnt;
          var colors = HueColors.cuiD3Scale();
          var pivotValues = $.map(rawDatum.counts(), function (p) {
            return p[_idxPivot];
          });
          pivotValues = pivotValues.filter(function (item, pos) {
            return pivotValues.indexOf(item) === pos;
          });
          pivotValues.forEach(function (val, pivotCnt) {
            var _data = [];
            $(rawDatum.counts()).each(function (cnt, item) {
              if (item[_idxPivot] === val) {
                if (isNotNullForCharts(item[_idxValue]) && isNotNullForCharts(item[_idxLabel])) {
                  _data.push({
                    x: _isXDate ? moment(item[_idxLabel]) : html2text(item[_idxLabel]),
                    y: item[_idxValue] * 1,
                    color: colors[pivotCnt % colors.length],
                    obj: item
                  });
                }
              }
            });
            _datum.push({
              key: html2text(val),
              values: _data
            });
          });
        }
      });

      // fills in missing values
      var longest = 0;
      var allXValues = [];
      _datum.forEach(function (d) {
        d.values.forEach(function (val) {
          if (allXValues.indexOf(val.x) === -1) {
            allXValues.push(val.x);
          }
        });
      });
      _datum.forEach(function (d) {
        allXValues.forEach(function (val) {
          if (!d.values.some(function (item) {
            return item.x === val;
          })) {
            var zeroObj = jQuery.extend({}, d.values[0]);
            zeroObj.y = 0;
            zeroObj.x = val;
            d.values.push(zeroObj);
          }
        });
        if (d.values.length > longest) {
          longest = d.values.length;
        }
      });

      // this is to avoid D3 js errors when the data the user is trying to display is bogus
      if (allXValues.length < longest) {
        _datum.forEach(function (d) {
          for (var i = d.values.length; i < longest; i++) {
            var zeroObj = jQuery.extend({}, d.values[0]);
            zeroObj.y = 0;
            zeroObj.x = '';
            d.values.push(zeroObj);
          }
        });
      }
      if (rawDatum.snippet.chartLimit()) {
        _datum = _datum.slice(0, rawDatum.snippet.chartLimit());
      }
      if (rawDatum.sorting === 'desc') {
        _datum.forEach(function (d) {
          d.values.sort(function (a, b) {
            if (a.x > b.x) {
              return -1;
            }
            if (a.x < b.x) {
              return 1;
            }
            return 0;
          });
        });
      } else {
        _datum.forEach(function (d) {
          d.values.sort(function (a, b) {
            if (a.x > b.x) {
              return 1;
            }
            if (a.x < b.x) {
              return -1;
            }
            return 0;
          });
        });
      }
    } else {
      rawDatum.snippet.result.meta().forEach(function (meta) {
        if (rawDatum.snippet.chartYMulti().indexOf(meta.name) > -1) {
          var col = meta.name;
          var _idxValue2 = -1;
          var _idxLabel2 = -1;
          var _isXDate2 = false;
          rawDatum.snippet.result.meta().forEach(function (icol, idx) {
            if (icol.name === rawDatum.snippet.chartX()) {
              _isXDate2 = icol.type.toUpperCase().indexOf('DATE') > -1;
              _idxLabel2 = idx;
            }
            if (icol.name === col) {
              _idxValue2 = idx;
            }
          });
          if (_idxValue2 > -1) {
            var _data = [];
            var colors = HueColors.cuiD3Scale();
            $(rawDatum.counts()).each(function (cnt, item) {
              if (isNotNullForCharts(item[_idxValue2]) && isNotNullForCharts(item[_idxLabel2])) {
                _data.push({
                  series: _plottedSerie,
                  x: _isXDate2 ? moment(item[_idxLabel2]) : html2text(item[_idxLabel2]),
                  y: item[_idxValue2] * 1,
                  color: colors[cnt % colors.length],
                  obj: item
                });
              }
            });
            if (rawDatum.sorting === 'asc') {
              _data.sort(function (a, b) {
                return a.y - b.y;
              });
            }
            if (rawDatum.sorting === 'desc') {
              _data.sort(function (a, b) {
                return b.y - a.y;
              });
            }
            if (rawDatum.snippet.chartLimit()) {
              _data = _data.slice(0, rawDatum.snippet.chartLimit());
            }
            _datum.push({
              key: col,
              values: _data
            });
            _plottedSerie++;
          }
        }
      });
    }
  }
  return _datum;
};
var scatterChartTransformer = function scatterChartTransformer(rawDatum) {
  var datum = {};
  if (rawDatum.snippet.chartX() != null && rawDatum.snippet.chartYSingle() != null) {
    var idxX = -1;
    var idxY = -1;
    var idxSize = -1;
    var idxGroup = -1;
    rawDatum.snippet.result.meta().forEach(function (icol, idx) {
      if (icol.name === rawDatum.snippet.chartX()) {
        idxX = idx;
      }
      if (icol.name === rawDatum.snippet.chartYSingle()) {
        idxY = idx;
      }
      if (icol.name === rawDatum.snippet.chartScatterSize()) {
        idxSize = idx;
      }
      if (icol.name === rawDatum.snippet.chartScatterGroup()) {
        idxGroup = idx;
      }
    });
    if (idxX > -1 && idxY > -1) {
      var createAndAddToArray = function createAndAddToArray(key, item) {
        if (!datum[key]) {
          datum[key] = [];
        }
        if (isNotNullForCharts(item[idxX]) && isNotNullForCharts(item[idxY])) {
          datum[key].push({
            x: item[idxX],
            y: item[idxY],
            shape: 'circle',
            size: idxSize > -1 ? item[idxSize] : 100,
            obj: item
          });
        }
      };
      if (idxGroup > -1) {
        $(rawDatum.counts()).each(function (cnt, item) {
          createAndAddToArray(item[idxGroup], item);
        });
      } else {
        $(rawDatum.counts()).each(function (cnt, item) {
          createAndAddToArray('distro', item);
        });
      }
    }
  }
  var returndDatum = [];
  Object.keys(datum).forEach(function (key) {
    returndDatum.push({
      key: key,
      values: rawDatum.snippet.chartLimit() ? datum[key].slice(0, rawDatum.snippet.chartLimit()) : datum[key]
    });
  });
  return returndDatum;
};
export default {
  pie: pieChartTransformer,
  map: mapChartTransformer,
  leafletMap: leafletMapChartTransformer,
  timeline: timelineChartTransformer,
  multiSerie: multiSerieChartTransformer,
  scatter: scatterChartTransformer
};