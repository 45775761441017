import { defineComponent } from 'vue';
import I18n from "../../../../../../utils/i18n";
import HueTable from "../../../../../../components/HueTable.vue";
import { generateValueColumnKey } from "./CounterSet";
import VarianceCell from "./VarianceCell.vue";
var DEFAULT_VALUE_COLUMN_TITLE = 'Counter Value';
var NAME_COLUMNS = [{
  label: I18n('Group Name'),
  key: 'groupName'
}, {
  label: I18n('Counter Name'),
  key: 'counterName'
}];
export default defineComponent({
  components: {
    HueTable: HueTable,
    VarianceCell: VarianceCell
  },
  props: {
    counters: {
      type: Object,
      required: true
    },
    hideSimilarValues: {
      type: Boolean,
      "default": false
    }
  },
  computed: {
    columns: function columns() {
      var valueColumns = [];
      this.counters.forEach(function (counterSet, index) {
        valueColumns.push({
          label: counterSet.title || DEFAULT_VALUE_COLUMN_TITLE,
          key: generateValueColumnKey(index),
          headerCssClass: counterSet.cssClass,
          cssClass: counterSet.cssClass
        });
      });
      if (this.counters.length > 1) {
        valueColumns.push({
          label: I18n('Variance'),
          key: 'variance'
        });
      }
      return NAME_COLUMNS.concat(valueColumns);
    },
    rows: function rows() {
      var _this = this;
      var rowHash = new Map();
      var rows;
      var counters = this.counters.filter(function (counterSet) {
        return counterSet.counters;
      });
      counters.forEach(function (counterSet, index) {
        var counterKey = generateValueColumnKey(index);
        counterSet.counters.forEach(function (counterGroup) {
          counterGroup.counters.forEach(function (counter) {
            var counterId = counterGroup.counterGroupName + counter.counterName;
            var row = rowHash.get(counterId);
            if (!row) {
              row = {
                groupName: counterGroup.counterGroupName,
                counterName: counter.counterName
              };
              rowHash.set(counterId, row);
            }
            row[counterKey] = counter.counterValue;
          });
        });
      });
      rows = Array.from(rowHash.values());
      if (this.hideSimilarValues) {
        rows = rows.filter(function (row) {
          return _this.isValuesDifferent(row, _this.counters.length);
        });
      }
      return rows;
    }
  },
  methods: {
    I18n: I18n,
    isValuesDifferent: function isValuesDifferent(row, valueCount) {
      if (valueCount > 1) {
        var firstVal = row[generateValueColumnKey(0)];
        for (var i = 1; i < valueCount; i++) {
          if (row[generateValueColumnKey(i)] !== firstVal) {
            return true;
          }
        }
        return false;
      }
      return true;
    }
  }
});