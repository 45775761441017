import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
_pushScopeId("data-v-591e46c3");
var _hoisted_1 = {
  "class": "labeled-info-title"
};
var _hoisted_2 = {
  "class": "labled-info-value"
};
var _hoisted_3 = {
  key: 1
};
_popScopeId();
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return _openBlock(), _createElementBlock("div", null, [_createElementVNode("div", _hoisted_1, _toDisplayString(_ctx.I18n(_ctx.label)), 1 /* TEXT */), _createElementVNode("div", _hoisted_2, [_ctx.showSlot ? _renderSlot(_ctx.$slots, "default", {
    key: 0
  }, undefined, true) : (_openBlock(), _createElementBlock("span", _hoisted_3, "-"))])]);
}