import { render } from "./SortSelector.vue?vue&type=template&id=45ffc45f&scoped=true"
import script from "./SortSelector.vue?vue&type=script&lang=ts"
export * from "./SortSelector.vue?vue&type=script&lang=ts"

import "./SortSelector.vue?vue&type=style&index=0&id=45ffc45f&lang=scss&scoped=true"
script.render = render
script.__scopeId = "data-v-45ffc45f"
/* hot reload */
if (module.hot) {
  script.__hmrId = "45ffc45f"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('45ffc45f', script)) {
    api.reload('45ffc45f', script)
  }
  
  module.hot.accept("./SortSelector.vue?vue&type=template&id=45ffc45f&scoped=true", () => {
    api.rerender('45ffc45f', render)
  })

}

script.__file = "desktop/core/src/desktop/js/apps/jobBrowser/components/impalaQueries/query-table/SortSelector.vue"

export default script