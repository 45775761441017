import { render } from "./QueryInfoTop.vue?vue&type=template&id=1ebc196d&scoped=true"
import script from "./QueryInfoTop.vue?vue&type=script&lang=ts"
export * from "./QueryInfoTop.vue?vue&type=script&lang=ts"

import "./QueryInfoTop.vue?vue&type=style&index=0&id=1ebc196d&lang=scss&scoped=true"
script.render = render
script.__scopeId = "data-v-1ebc196d"
/* hot reload */
if (module.hot) {
  script.__hmrId = "1ebc196d"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('1ebc196d', script)) {
    api.reload('1ebc196d', script)
  }
  
  module.hot.accept("./QueryInfoTop.vue?vue&type=template&id=1ebc196d&scoped=true", () => {
    api.rerender('1ebc196d', render)
  })

}

script.__file = "desktop/core/src/desktop/js/apps/jobBrowser/components/impalaQueries/query-details/QueryInfoTop.vue"

export default script