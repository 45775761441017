import { render } from "./QueryDetailsDiff.vue?vue&type=template&id=216353e6&scoped=true"
import script from "./QueryDetailsDiff.vue?vue&type=script&lang=ts"
export * from "./QueryDetailsDiff.vue?vue&type=script&lang=ts"

import "./QueryDetailsDiff.vue?vue&type=style&index=0&id=216353e6&lang=scss&scoped=true"
script.render = render
script.__scopeId = "data-v-216353e6"
/* hot reload */
if (module.hot) {
  script.__hmrId = "216353e6"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('216353e6', script)) {
    api.reload('216353e6', script)
  }
  
  module.hot.accept("./QueryDetailsDiff.vue?vue&type=template&id=216353e6&scoped=true", () => {
    api.rerender('216353e6', render)
  })

}

script.__file = "desktop/core/src/desktop/js/apps/jobBrowser/components/impalaQueries/query-details/QueryDetailsDiff.vue"

export default script