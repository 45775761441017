import { render } from "./QueryTable.vue?vue&type=template&id=c8a18ab0&scoped=true"
import script from "./QueryTable.vue?vue&type=script&lang=ts"
export * from "./QueryTable.vue?vue&type=script&lang=ts"

import "./QueryTable.vue?vue&type=style&index=0&id=c8a18ab0&lang=scss&scoped=true"
script.render = render
script.__scopeId = "data-v-c8a18ab0"
/* hot reload */
if (module.hot) {
  script.__hmrId = "c8a18ab0"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('c8a18ab0', script)) {
    api.reload('c8a18ab0', script)
  }
  
  module.hot.accept("./QueryTable.vue?vue&type=template&id=c8a18ab0&scoped=true", () => {
    api.rerender('c8a18ab0', render)
  })

}

script.__file = "desktop/core/src/desktop/js/apps/jobBrowser/components/impalaQueries/query-table/QueryTable.vue"

export default script