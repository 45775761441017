import { render } from "./QueryDetails.vue?vue&type=template&id=5f0bd868&scoped=true"
import script from "./QueryDetails.vue?vue&type=script&lang=ts"
export * from "./QueryDetails.vue?vue&type=script&lang=ts"

import "./QueryDetails.vue?vue&type=style&index=0&id=5f0bd868&lang=scss&scoped=true"
script.render = render
script.__scopeId = "data-v-5f0bd868"
/* hot reload */
if (module.hot) {
  script.__hmrId = "5f0bd868"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('5f0bd868', script)) {
    api.reload('5f0bd868', script)
  }
  
  module.hot.accept("./QueryDetails.vue?vue&type=template&id=5f0bd868&scoped=true", () => {
    api.rerender('5f0bd868', render)
  })

}

script.__file = "desktop/core/src/desktop/js/apps/jobBrowser/components/impalaQueries/query-details/QueryDetails.vue"

export default script