import { normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
_pushScopeId("data-v-fcc1af68");
var _hoisted_1 = {
  key: 0
};
var _hoisted_2 = {
  "class": "variance-bar"
};
_popScopeId();
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return _ctx.varianceTxt ? (_openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createElementVNode("div", {
    "class": "value2-bar",
    style: _normalizeStyle({
      width: _ctx.variancePercent + '%'
    })
  }, null, 4 /* STYLE */)]), _createTextVNode(" " + _toDisplayString(_ctx.varianceTxt), 1 /* TEXT */)])) : _createCommentVNode("v-if", true);
}