import { defineComponent } from 'vue';
import StatusIndicator from "../../../../../components/StatusIndicator.vue";
import LabeledInfo from "./LabeledInfo.vue";
export default defineComponent({
  components: {
    StatusIndicator: StatusIndicator,
    LabeledInfo: LabeledInfo
  },
  props: {
    query: {
      type: Object,
      required: true
    }
  },
  methods: {
    copyQueryId: function copyQueryId(queryId) {
      navigator.clipboard.writeText(queryId);
    }
  }
});